<template>
    <b-modal
      id="paymentModal" size="lg" modal-class="main-modal page-main-block" ok-only ok-variant="success modal-ok" header-class="text-white border-0" header-close-variant="orange"
       title-class="text-center underlined-muted-title" footer-class="border-0" hide-footer body-class="pt-0" centered>
      <b-tabs class="pb-3 px-0 text-center home-pills" pills>
        <b-tab class="col-12">
            <template slot="title" class="mx-0">
            <div class=" py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab py-2 w-100" name="withdraw">
                    Депозит
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
            <div class="withdraw" ref="withdraw">
                <div class="withdraw-inner">
                    <DepositBlock />
                </div>
            </div>
            </div>
        </b-tab>
        <b-tab class="col-12">
            <template slot="title" class="mx-0">
            <div class=" py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab py-2 w-100" name="withdraw">
                    Выплата
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
            <div class="withdraw" ref="withdraw">
                <div class="withdraw-inner">
                  <WithdrawBlock withdrawType = "regular" />
                </div>
            </div>
            </div>
        </b-tab>

        <b-tab class="col-12" v-if="vipWithdrawMethods.length > 0">
          <template slot="title" class="mx-0">
            <div class=" py-0 col-12 text-center category-item px-0">
              <button class="btn pay-tab py-2 w-100" name="vip_withdraw">
                VIP Выплата
              </button>
            </div>
          </template>

          <div class="pay-tab-body">
            <div class="withdraw" ref="vip_withdraw">
              <div class="withdraw-inner">
                <WithdrawBlock withdrawType = "vip" />
              </div>
            </div>
          </div>
        </b-tab>


        <b-tab v-if="userData">
            <template slot="title" class="mx-0">
            <div class="tab-title py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab py-2 w-100" name="history">
                    История
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
            <div class="history" ref="history">
                <div class="history-inner">
                   <div>
                    <b-tabs class="pb-3 px-0 text-center" pills>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 text-center category-item px-0">
                                <button class="btn  pay-tab py-2 w-100" name="history_withdraw">
                                    Депозит
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="depositHistory ? depositHistory.deposits : null "/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 col-12 text-center category-item px-0">
                                <button class="btn  pay-tab py-2 w-100" name="history_withdraw">
                                    Выплата
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="withdrawHistory ? withdrawHistory.withdraws : null"/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                    </b-tabs> 
                   </div>
                </div>
            </div>
            </div>
        </b-tab>
      </b-tabs> 
    </b-modal>
  </template>
  
  <script>
  import DepositBlock from './DepositBlock.vue'
  import WithdrawBlock from './WithdrawBlock.vue';
  import History from './History.vue';

  export default {
    name: 'PaymentModal',
    components: {
        DepositBlock,
        WithdrawBlock,
        History
    },
    computed: {
        withdrawHistory() {
           return this.$store.getters.getWithdrawHistory
        },
        depositHistory() {
           return this.$store.getters.getDepositHistory
        },
        userData() {
            return this.$store.getters.userData
        },
        vipWithdrawMethods() {
          return this.$store.getters.getWithdrawMethods.vip
        },
    },
    mounted() {
      this.$store.dispatch('fetchAvailableWithdrawMethods')
    }
  }
</script>


<style scoped>
    .pay-tab{
        color: #fff!important;
    }
 
    .nav-pills .nav-link.active .pay-tab {
        border-bottom: 2px solid #860019;
    }
    
    .pay-tab:active,
    .pay-tab:focus {
        outline: none; 
        box-shadow: none!important;
    }

    @media (max-width: 992px) {
        #paymentModal{
            padding-left: 0 !important;
        }
    }
    @media (max-width:402px) {
        .pay-tab{
         font-size: 13px;
        }
    }
    @media (max-width:338px) {
        .pay-tab{
         font-size: 11px;
        }
        .nav-pills{
        height: 43px;
    }
    }
</style>
  
<style>
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
        color: aliceblue;
        background-color: unset!important;
    }

    .nav-pills .nav-link {
        padding:0 0.5rem;
    }
    .nav-pills{
        border-bottom: 1px solid rgb(143, 141, 141);
    }
    @media (max-width:402px) {
        .nav-pills .nav-link {
            padding:0 0.6rem;
        }
    }
    @media (max-width:338px) {
        .nav-pills{
        height: 43px;
    }
    }
</style>